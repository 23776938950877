.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff; 
  border-bottom: none; 
  box-shadow: none; 
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%; 
  margin: 0 auto;
}

.logo {
  width: 120px;
  margin-right: auto;
  padding: 0px;
}

.desktopMenu {
  display: flex;
  gap: 2rem; 
}

.link {
  text-decoration: none;
  color: #000;
  font-weight: bold; 
  transition: color 0.3s;
}

.link:hover {
  color: #060992;
}

.mobileMenuButton {
  display: none;
}

@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }

  .mobileMenuButton {
    display: block;
  }
}